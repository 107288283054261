@import '~styles/core/media-queries';
@import '~styles/core/colors';

.navigation {
  @include desktop {
    display: none;
  }

  position: fixed;
  z-index: 1;
  bottom: 0;
  width: 100%;
  height: 72px;
  display: flex;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0 -2px 11px 0 rgba(0, 0, 0, 0.11);

  .button {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin: 11px 12px;
    background-color: transparent !important;
    font-family: PoppinsRegular, sans-serif;
    font-size: 12px;
    color: $pantone;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    border: 0;
    border-bottom: 2px solid transparent;
    transition: border-bottom-color 150ms cubic-bezier(0.4, 0, 0.2, 1);

    &.active:not(:disabled) {
      font-family: PoppinsBold, sans-serif;
      border-bottom-color: $pantone;
    }

    &:disabled {
      color: $iron;
    }

    .icon {
      width: 20px;
      height: 20px;

      &.mirror {
        transform: scaleX(-1);
      }

      &.rotated180 {
        transform: rotate(180deg);
      }
    }
  }
}

.more_modal {
  @include desktop {
    display: none;
  }

  :global {
    .modal {
      height: calc(100vh - 72px);

      .modal-dialog {
        margin: 0;
        height: 100%;
        max-width: 100%;

        .modal-content {
          border: 0;
          border-radius: 0;
          height: 100%;

          .modal-body {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: $pantone;
            padding: 0;

            :local {
              .menu {
                position: relative;
                flex: 3;
                width: clamp(280px, calc(100vw * 0.75), 350px);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 50px 0;
              }

              .button,
              .logout_button {
                z-index: 1;
                width: 100%;
              }

              .button {
                background-color: transparent;
                margin: 5px 0;
              }

              .logout_button {
                position: absolute;
                bottom: -28px;
              }

              .pattern {
                flex: 1;
              }
            }
          }
        }
      }

      &.fade .modal-dialog {
        transition: transform 0.15s ease-out;
        transform: translate(100%, 0);
      }

      &.show .modal-dialog {
        transform: none;
      }
    }
  }
}
