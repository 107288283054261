@mixin mobile {
  @media only screen and (max-width: 1023px) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: 1024px) {
    @content;
  }
}
