.svg_icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  vertical-align: middle;

  * {
    transition-property: fill, opacity, stroke;
    transition-duration: 150ms, 150ms, 150ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1),
      cubic-bezier(0.4, 0, 0.2, 1);
    max-width: 100%;
    max-height: 100%;
  }

  svg {
    vertical-align: middle;
    width: inherit;
    height: inherit;
  }

  &.clickable {
    cursor: pointer;
  }
}
