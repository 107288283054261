@import '~styles/core/media-queries';
@import '~styles/core/colors';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: $aqua_haze;
  overflow-x: hidden;
  position: relative;

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: white;

    @include mobile {
      height: 100px;
      padding: 20px;
    }

    @include desktop {
      height: 136px;
      padding: 33px 55px;
    }

    .logo {
      @include mobile {
        width: 52px;
        height: 52px;
      }

      @include desktop {
        width: 70px;
        height: 70px;
      }
    }
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}
