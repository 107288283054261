@import '~styles/core/media-queries';
@import '~styles/core/colors';

.page {
  padding: 45px;
  text-align: center;

  & > *:not(:first-child) {
    @include mobile {
      margin-top: 10px;
    }

    @include desktop {
      margin-top: 15px;
    }
  }

  .icon {
    @include mobile {
      height: 68px;
      width: 68px;
    }

    @include desktop {
      height: 71px;
      width: 71px;
    }
  }

  .title {
    font-family: PoppinsBold, sans-serif;
    color: $pantone;
    text-transform: uppercase;

    @include mobile {
      font-size: 16px;
      letter-spacing: 0.5px;
    }

    @include desktop {
      font-size: 35px;
      letter-spacing: -0.53px;
    }
  }

  .message {
    font-family: PoppinsRegular, sans-serif;
    color: $bunker;

    @include mobile {
      font-size: 14px;
      letter-spacing: 0.44px;
    }

    @include desktop {
      font-size: 16px;
      letter-spacing: 0.1px;
    }
  }
}
