.container {
  width: 100%;
  background: repeating-linear-gradient(45deg, #862633, #862633 20px, #7c232f 0, #7e2430 40px);
  font-size: 16px;
  font-family: ITCAvantGardeStd-Demi, sans-serif;
  font-weight: bold;
  color: white;
  padding: 10px 20px;
  text-align: center;
}
