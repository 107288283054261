html,
body,
div[id='root'] {
  height: 100%;
  min-height: 100%;
  min-width: 360px !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

pre {
  white-space: pre-wrap;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  &:focus {
    outline: none;
  }
}

label {
  margin: 0;
}

a {
  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

.shake {
  animation: shake 0.75s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate(0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate(-1px, 0);
  }

  20%,
  80% {
    transform: translate(2px, 0);
  }

  30%,
  50%,
  70% {
    transform: translate(-4px, 0);
  }

  40%,
  60% {
    transform: translate(4px, 0);
  }
}
