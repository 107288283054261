@import '~styles/core/media-queries';
@import '~styles/core/colors';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: white;

  @include mobile {
    height: 100px;
    padding: 20px;
  }

  @include desktop {
    height: 136px;
    padding: 33px 55px;
  }

  .navigation {
    display: flex;
    align-items: center;

    @include mobile {
      display: none;
    }

    & > *:not(:first-child) {
      margin-left: 20px;
    }

    .navigation_button {
      position: relative;
      height: 25px;
      background-color: transparent !important;
      font-family: PoppinsRegular, sans-serif;
      font-size: 16px;
      letter-spacing: 0.5px;
      color: $pantone;
      outline: 0 !important;
      box-shadow: none !important;
      text-align: center;
      text-transform: uppercase;
      padding: 0 3px;
      border: none;
      border-radius: 0;
      border-bottom: 3px solid transparent;
      transition: border-bottom-color 150ms linear;

      &:disabled {
        color: $iron;
      }

      &:active:not(:disabled) {
        color: $pantone !important;
      }

      span:first-child {
        font-family: PoppinsBold, sans-serif;
        color: transparent;
      }

      span:not(:first-child) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &:hover:not(:disabled) {
        font-family: PoppinsBold, sans-serif;
      }

      &[aria-expanded='true'],
      &.active:not(:disabled) {
        font-family: PoppinsBold, sans-serif;
        border-bottom-color: $pantone;
      }

      &.active:not(:disabled) {
        cursor: default;
      }
    }

    :global {
      .dropdown-menu.show {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 25px;
        left: 50% !important;
        transform: translate3d(-50%, 25px, 0) !important;

        .dropdown-item {
          height: 30px;
          width: unset;
          background-color: transparent !important;
          font-family: PoppinsRegular, sans-serif;
          font-size: 16px;
          letter-spacing: 0.5px;
          color: $pantone;
          outline: 0 !important;
          box-shadow: none !important;
          text-align: center;
          text-transform: uppercase;
          padding: 0;
          border: none;
          border-radius: 0;
          border-bottom: 2px solid transparent;
          transition: border-bottom-color 150ms linear;

          &:disabled {
            color: $iron;
          }

          &:active {
            color: $pantone !important;
            border-bottom-color: $pantone !important;
          }

          &:not(:first-child) {
            margin-top: 5px;
          }

          &.active {
            border-bottom-color: $pantone;
            cursor: default;
          }

          &[aria-expanded='true'],
          &:hover:not(:disabled) {
            border-bottom-color: $pantone;
          }
        }
      }
    }

    .logout_button {
      width: 145px;
      min-height: 44px;
      font-family: PoppinsRegular, sans-serif;
      font-size: 16px;
      letter-spacing: 0.5px;
      margin-left: 40px;
      padding: 0;
      background-color: $pantone;
      color: white;
    }
  }
}
