/*
  Custom fonts have issue with vertical align on ios.
  (https://stackoverflow.com/questions/5414730/custom-installed-font-not-displayed-correctly-in-uilabel)
  All fonts were updated to fix align problem (ascent = ascent + line gap, line gap = 0)
 */

@mixin font-face($family, $folder, $weight, $style) {
  @font-face {
    font-family: '#{$family}';
    font-style: $style;
    font-weight: $weight;
    src: url('#{$folder}/#{$family}-#{$weight}.eot');
    src: local(''),
      url('#{$folder}/#{$family}-#{$weight}.eot?#iefix') format('embedded-opentype'),
      url('#{$folder}/#{$family}-#{$weight}.woff2') format('woff2'),
      url('#{$folder}/#{$family}-#{$weight}.woff') format('woff'),
      url('#{$folder}/#{$family}-#{$weight}.ttf') format('truetype'),
      url('#{$folder}/#{$family}-#{$weight}.svg#Poppins') format('svg');
  }
}

@include font-face('PoppinsRegular', 'fonts/Poppins', 400, normal);
@include font-face('PoppinsMedium', 'fonts/Poppins', 500, normal);
@include font-face('PoppinsSemiBold', 'fonts/Poppins', 600, normal);
@include font-face('PoppinsBold', 'fonts/Poppins', 700, normal);
