@import '~styles/core/media-queries';
@import '~styles/core/colors';

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  outline: 0 !important;
  text-align: center;
  font-family: PoppinsBold, sans-serif;
  text-transform: uppercase;

  &:disabled,
  &.loading {
    cursor: default;
  }
}

// Sizes

.primary {
  @include mobile {
    width: 100%;
    min-height: 42px;
    font-size: 14px;
    letter-spacing: 0.49px;
    padding: 0 16px;
  }

  @include desktop {
    width: 275px;
    min-height: 56px;
    font-size: 18px;
    letter-spacing: 0.63px;
    padding: 0 32px;
  }
}

.large {
  min-height: 56px;
  font-size: 18px;
  letter-spacing: 0.63px;
  padding: 0 32px;

  @include mobile {
    width: 100%;
  }

  @include desktop {
    width: 275px;
  }
}

// Colors

.blue {
  &.filled {
    background-color: $jelly_bean;
    color: white;

    &:disabled {
      background-color: $iron;
    }
  }

  &.outlined {
    background-color: white;
    color: $pantone;
    border: 2px solid $pantone;

    &:disabled {
      color: $iron;
      border: 2px solid $iron;
    }
  }
}

// Loading spinner

.blue.loading {
  .spinner {
    margin-right: 10px;
  }

  &.large .spinner {
    height: 18px;
    width: 18px;
  }

  &.primary .spinner {
    height: 14px;
    width: 14px;
  }
}
