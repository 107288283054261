@import '~styles/core/media-queries';

.logo {
  @include mobile {
    width: 52px;
    height: 52px;
  }

  @include desktop {
    width: 70px;
    height: 70px;
  }

  &.clickable {
    cursor: pointer;
  }
}
