@import '~styles/core/media-queries';
@import '~styles/core/colors';

.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  border-top: 1px solid $gaf-gray;
  background-color: white;

  @include mobile {
    justify-content: flex-start;
  }

  .links_wrapper {
    margin: 24px 0;

    @include mobile {
      margin: 16px 0 88px 24px;
    }

    .button {
      font-family: PoppinsSemiBold, sans-serif;
      font-weight: 600;

      @include mobile {
        justify-content: flex-start;
      }
    }

    .manage_cookies_button {
      box-sizing: border-box;
      padding: 0 16px;
    }

    .privacy_icon {
      width: 28px;
      cursor: pointer;

      @include mobile {
        margin: 8px 16px;
      }
    }

    .first_row,
    .second_row {
      display: flex;
      justify-content: center;
      align-items: center;

      @include mobile {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }

    .first_row {
      @include desktop {
        margin-bottom: 4px;
      }
    }
  }
}
