@import '~styles/core/media-queries';
@import '~styles/core/colors';

.container {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  @include mobile {
    &.with_navigation {
      padding-bottom: 92px;
    }
  }

  &.centered {
    justify-content: center;
  }

  &:not(.centered) {
    justify-content: flex-start;
  }
}

.section {
  max-width: 740px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.title {
  @include mobile {
    margin-top: 30px;
  }

  @include desktop {
    margin-top: 50px;
  }

  h1 {
    font-family: PoppinsBold, sans-serif;
    text-transform: uppercase;
    color: $bay_of_many;

    @include mobile {
      font-size: 23px;
      letter-spacing: -0.35px;
    }

    @include desktop {
      font-size: 35px;
      letter-spacing: -0.53px;
    }
  }

  p {
    font-family: PoppinsRegular, sans-serif;
    color: black;

    @include mobile {
      margin: 10px 0;
      font-size: 14px;
      letter-spacing: 0.09px;
    }

    @include desktop {
      margin: 5px 0;
      font-size: 16px;
      letter-spacing: 0.1px;
    }
  }
}

.content {
  margin-top: 30px;
  background-color: white;
  box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.29);

  @include mobile {
    padding: 30px 20px;
  }

  @include desktop {
    padding: 40px;
  }
}
