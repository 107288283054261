@import '~styles/core/colors';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  width: 100%;
  background-color: $aqua_haze;
  overflow-x: hidden;
  position: relative;
}
