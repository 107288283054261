$pantone: #002e5d;
$jelly-bean: #2774ae;
$rolling-stone: #74777a;
$mystic: #e5eaee;
$bunker: #101820;
$iron: #d0d1d1;
$aqua-haze: #f7f9fa;
$bay-of-many: #1f3b78;
$midnight: #002143;
$alizarin-crimson: #d71920;
$edward: #b1b3b3;
$tuna: #393946;
$cardinal: #c4222a;
$iron-light: #d8d9d9;
$quill-gray: #d9d9d6;
$revell: #eee;
$aqua-haze: #f7f9fa;
$shakespeare: #4390ca;
$gaf-gray: #75787b;
