/* Global reset
*******************************************************************************/
html,
body,
div,
a,
span,
object,
iframe,
p,
blockquote,
pre,
img,
dl,
dt,
dd,
ol,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
fieldset,
form,
legend,
textarea,
button,
input,
select {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* =HTML5 display definitions
*******************************************************************************/
article,
aside,
details,
figcaption,
figure,
footer,
header,
nav,
section {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden] {
  display: none;
}

svg:not(:root) {
  overflow: hidden;
}

/* Tags reset
*******************************************************************************/
html {
  font-size: 100%;
  text-size-adjust: 100%;
}

html,
button,
input,
select,
textarea {
  font-family: sans-serif;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: none;
}

a {
  text-decoration: none;
  background: transparent;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

ul,
ol {
  list-style: none;
}

img {
  width: auto;
  height: auto;
  border: 0;
  vertical-align: middle;
}

/* Normalizing Tags
*******************************************************************************/
small {
  font-size: 85%;
}

strong,
th {
  font-weight: bold;
}

td,
td img {
  vertical-align: top;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 0;
}

textarea,
select,
input {
  border-radius: 0;
  box-shadow: none;
}

p:empty {
  display: none;
}

/* Normalizing form elements
*******************************************************************************/
fieldset {
  border: none;
}

textarea,
button,
input,
select {
  font-size: 100%;
  vertical-align: baseline;
}

textarea {
  overflow: auto;
  min-height: 30px;
  max-height: 400px;
  resize: vertical;
}

input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
  vertical-align: middle;
}

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  cursor: pointer;
}

button[disabled],
input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
